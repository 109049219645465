<template>
  <div class="mark">
    <van-sticky>
      <van-nav-bar
        :title="title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
      />
    </van-sticky>

    <div class="mark-main">
      <div class="main-title">预约挂号</div>
      <div class="main-box">
        <van-form @failed="onFailed">
          <van-cell-group inset>
            <van-field
              v-model="name"
              label="科室"
              placeholder="请输入用户名"
              readonly
            />
          </van-cell-group>
          <van-cell-group inset>
            <van-field
              v-model="userName"
              label="就诊人"
              placeholder="请输入姓名"
              :rules="[{ validator, message: '请输入姓名' }]"
            />
          </van-cell-group>
          <van-cell-group inset>
            <van-field
              v-model="userTel"
              label="手机号码"
              placeholder="请输入手机号"
              type="tel"
              :rules="[{ validator, message: '请输入手机号' }]"
            />
          </van-cell-group>
          <van-cell-group inset>
            <van-field
              v-model="userAge"
              label="年龄"
              placeholder="请输入年龄"
              type="digit"
              :rules="[{ validator, message: '请输入年龄' }]"
            />
          </van-cell-group>
          <van-cell-group inset>
            <van-field
              v-model="userDate"
              label="就诊日期"
              placeholder="请选择就诊日期"
              readonly
              @click="showCalendarOption('userDate')"
              :rules="[{ validator, message: '请选择就诊日期' }]"
            />
          </van-cell-group>
          <van-cell-group inset>
            <van-field
              v-model="userTime"
              label="就诊时间"
              placeholder="请选择就诊时间"
              readonly
              @click="showSelectOption('userTime')"
              :rules="[{ validator, message: '请选择就诊时间' }]"
            />
          </van-cell-group>
        </van-form>
      </div>
      <div class="mark-submit">
        <van-button
          :disabled="isDisabled"
          type="success"
          block
          @click="toSubmit()"
          >立即预约</van-button
        >
      </div>
    </div>

    <van-calendar
      v-model:show="showCalendar"
      :min-date="minDate"
      :max-date="maxDate"
      @confirm="onConfirm2"
    />
    <van-popup v-model:show="showSelect" position="bottom">
      <van-picker
        :columns="columnsOption"
        @confirm="onConfirm"
        @cancel="showSelect = false"
      />
    </van-popup>
  </div>
</template>
<script>
import { showToast, showSuccessToast, showFailToast } from "vant";
export default {
  name: "Home",
  data() {
    return {
      Authorization: localStorage.getItem(window.lsn),
      seed: localStorage.getItem(window.ref),
      name: this.$route.query.name,
      id: this.$route.query.id,
      title: this.$route.meta.title,
      userName: "",
      userTel: "",
      userAge: "",
      userDate: "",
      userTime: "",
      showCalendar: false,
      showSelect: false,
      isDisabled: false,
      minDate: new Date(parseInt(Date.parse(new Date()) + 86400000 * 1)),
      maxDate: new Date(parseInt(Date.parse(new Date()) + 86400000 * 7)),
      filterList: {
        userTime: {
          value: "",
          option: [
            { text: "8:00 - 10:00", value: "1" },
            { text: "10:00 - 12:00", value: "2" },
            { text: "14:00 - 16:00", value: "3" },
            { text: "16:00 - 18:00", value: "4" },
          ],
        },
      },
    };
  },
  activated() {
    this.name = this.$route.query.name;
    this.id = this.$route.query.id;
  },
  mounted() {
    console.log(this.name, this.id);
  },
  methods: {
    onClickLeft() {
      history.back();
    },
    validator(val) {
      if (val == "") {
        return false;
      }
    },
    showSelectOption(name) {
      this.optionName = name;
      this.columnsOption = this.filterList[name].option;
      this.showSelect = true;
    },
    showCalendarOption(name) {
      this.optionName = name;
      this.showCalendar = true;
    },
    onConfirm(val) {
      console.log(val.selectedValues[0]);
      this.userTime = val.selectedOptions[0].text;
      this.filterList[this.optionName].value = val.selectedValues[0];
      this.showSelect = false;
    },
    onConfirm2(date) {
      if (date) {
        var resData = date.getDate();
        var resMonth = date.getMonth() + 1;
        if (resMonth <= 9) {
          resMonth = "0" + resMonth;
        }
        if (resData <= 9) {
          resData = "0" + resData;
        }
        this.userDate = `${date.getFullYear()}-${resMonth}-${resData}`;
        this.showCalendar = false;
      }
    },
    // 提交表单
    toSubmit() {
      if (this.userName == "") {
        showToast("请填写就诊人姓名");
        return false;
      }
      if (this.userTel == "") {
        showToast("请填写手机号码");
        return false;
      }
      if (this.userAge == "") {
        showToast("请填写年龄");
        return false;
      }
      if (this.userDate == "") {
        showToast("请选择就诊日期");
        return false;
      }
      if (this.userTime == "") {
        showToast("请选择就诊时间");
        return false;
      }
      this.isDisabled = true;
      this.axios({
        url: this.$store.state.api + "/order",
        method: "post",
        data: {
          name: this.name,
          gid: this.id,
          userName: this.userName,
          userTel: this.userTel,
          userAge: this.userAge,
          userDate: this.userDate,
          userTime: this.userTime,
          seed: this.seed,
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: this.Authorization,
        },
      }).then((res) => {
        console.log(res);
        this.isDisabled = false;
        if (res.data) {
          showSuccessToast("预约成功！");
          history.back();
        } else {
          showFailToast("预约失败");
        }
      });
    },
  },
};
</script>

<style lang="scss">
.mark {
  background: #f9f9f9;
  .van-tabbar-item {
    font-size: 14px;
  }
  .van-nav-bar .van-icon-arrow-left {
    font-size: 24px;
    color: #969799;
  }
  .mark-main {
    height: 800px;
    background: url(../assets/mark-bg.jpg) center no-repeat;
    background-size: cover;
    .main-title {
      font-size: 30px;
      color: #fff;
      height: 50px;
      padding: 100px 0 50px 0;
      text-align: center;
      font-weight: bold;
      letter-spacing: 5px;
    }
    .main-box {
      padding: 20px 20px;
      .van-cell-group {
        margin-bottom: 10px;
      }
    }
  }
  .mark-submit {
    width: 80%;
    padding: 20px;
    margin: 0 auto;
    .van-button {
      font-size: 16px;
      letter-spacing: 3px;
    }
  }
}
</style>
